/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

import * as React from 'react'

import {StoreProvider} from '@/context/StoreContext'
export const wrapRootElement = ({element}) => {
  return <StoreProvider>{element}</StoreProvider>
}
