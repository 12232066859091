/* eslint-disable no-warning-comments */
/* eslint-disable no-console */

import fetch from 'isomorphic-fetch'
import React, {createContext, useContext, useEffect, useState} from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient(
  {
    domain: `eqwin-1195.myshopify.com`,
    storefrontAccessToken: `7b9b6694e929e00f44039b6680d9e869`,
  },
  fetch
)

const defaultValues = {
  loading: false,
  addVariantToCart: () => {},
  removeVariantFromCart: () => {},
  updateVariantFromCart: () => {},
  client,
  checkout: {
    id: '',
    lineItems: [],
    webUrl: '',
  },
}

export const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({children}) => {
  const [loading, setLoading] = useState(false)
  const [checkout, setCheckout] = useState(defaultValues.checkout)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  const addVariantToCart = async (variantId, quantity) => {
    setLoading(true)

    if (checkout.id === '') {
      console.error('No checkout ID assigned.')
      return
    }

    const checkoutID = checkout.id
    const parsedQuantity = parseInt(quantity, 10)

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parsedQuantity,
      },
    ]

    try {
      const res = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate)
      setCheckout(res)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(`Error in addVariantToCart: ${error}`)
    }
  }

  const removeVariantFromCart = async (variantId) => {
    setLoading(true)

    try {
      if (checkout.lineItems.length < 1) throw new Error('Cart is empty')

      let lineItemID = false
      checkout.lineItems?.forEach((item) => {
        if (item.attrs?.id.value === variantId) {
          lineItemID = variantId
        }
      })

      if (!lineItemID) {
        console.log('Product not in cart')
        return
      }

      const res = await client.checkout.removeLineItems(checkout.id, [lineItemID])
      setCheckout(res)
    } catch (error) {
      setLoading(false)
      console.error(`Error in removeLineItem: ${error}`)
    }
  }

  const updateVariantFromCart = async (variantId, quantity) => {
    setLoading(true)

    try {
      let lineItemID = false
      checkout.lineItems?.forEach((item) => {
        if (item.attrs?.id.value === variantId) {
          lineItemID = variantId
        }
      })

      if (!lineItemID) {
        console.log('Product not in cart')
        return
      }
      const res = await client.checkout.updateLineItems(checkout.id, [
        {id: variantId, quantity: quantity},
      ])

      setCheckout(res)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(`Error in updateLineItem: ${error}`)
    }
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID)
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeVariantFromCart,
        updateVariantFromCart,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('useStore must be used within StoreContext')
  }

  return context
}

export default useStore
